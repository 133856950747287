import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

function SeatsAvailability({ totalSeats, occupiedSeats }) {
    const { t } = useTranslation();

    const freeSeats = totalSeats - occupiedSeats;
    const occupancyPercentage = (occupiedSeats / totalSeats) * 100;

    let progressColor = '#90caf9';
    if (occupancyPercentage >= 50 && occupancyPercentage < 80) {
        progressColor = '#2196f3';
    } else if (occupancyPercentage >= 80) {
        progressColor = '#1976d2';
    }

    return (
        <Box marginBottom={2}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                <strong>{t('free_seats')}:</strong> {freeSeats}
            </Typography>

            <LinearProgress
                variant="determinate"
                value={occupancyPercentage}
                sx={{
                    marginBottom: 1,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: progressColor,
                    },
                }}
            />
        </Box>
    );
}

export default SeatsAvailability;
