import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function EnrollmentForm({ courseDetails, onConfirm }) {
    const { t } = useTranslation(); // Получаем функцию перевода
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        contactInfo: '',
        gdprConsent: false,
    });

    const [charCount, setCharCount] = useState(255); // Для подсчёта символов

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (name === 'message') {
            setCharCount(255 - value.length); // Обновляем оставшиеся символы
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.gdprConsent) {
            alert(t('gdprConsentError')); // Используем перевод для ошибки
            return;
        }

        const bookingData = {
            user: {
                email: formData.email,
                fullName: formData.name,
                contactInfo: formData.contactInfo, // Передаем контактную информацию
            },
            course: {
                id: courseDetails.id,
                title: courseDetails.title,
                date: courseDetails.date
            },
            paid: false,
            message: formData.message
        };

        try {
            const response = await axios.post('/api/bookings', bookingData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Booking created:', response.data);

            onConfirm(response.data.course);
        } catch (error) {
            console.error('Error creating booking:', error);
            alert(t('bookingError')); // Используем перевод для ошибки
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h5" gutterBottom>
                {t('enrollInCourse')} {/* Перевод для заголовка */}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label={t('name')}
                        variant="outlined"
                        fullWidth
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('email')}
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('contactInfo')} // Перевод для нового поля
                        variant="outlined"
                        fullWidth
                        name="contactInfo"
                        value={formData.contactInfo}
                        onChange={handleChange}
                        placeholder={t('contactInfoPlaceholder')} // Подсказка
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('message')}
                        variant="outlined"
                        fullWidth
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 255 }} // Ограничение на ввод
                    />
                    <Typography variant="body2" color="textSecondary" align="right" style={{ marginTop: '4px' }}>
                        {t('charactersLeft', { count: charCount })} {/* Перевод для оставшихся символов */}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.gdprConsent}
                                onChange={handleChange}
                                name="gdprConsent"
                                color="primary"
                                required
                            />
                        }
                        label={t('gdprConsentLabel')}
                    />
                </Grid>
            </Grid>
            <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '20px' }}>
                {t('enrollNow')}
            </Button>
        </form>
    );
}

export default EnrollmentForm;
