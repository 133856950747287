import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom"; // Импортируем BrowserRouter
import Header from "./Header"; // Импортируем Header
import Footer from "./Footer"; // Импортируем Footer
import AppRoutes from "./Routes"; // Подключаем файл маршрутов
import './index.css';
import './utils/i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <Header/>
        <body>
        <AppRoutes/>
        </body>
        <Footer/>
    </Router>
);