import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    CircularProgress,
    IconButton,
} from "@mui/material";
import fetchWithAuth from "../utils/fetchWithAuth"; // Импорт утилиты
import ConfirmationDialog from "../components/ConfirmationDialog"; // Импорт диалогового окна
import LanguageIcon from "@mui/icons-material/Language"; // Для переключения языка

function AdminCourseList() {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState("en"); // Текущий выбранный язык
    const navigate = useNavigate();

    useEffect(() => {
        // Запрос к серверу для получения курсов
        fetchWithAuth("/api/courses/allCourses")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch courses");
                }
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setCourses(data);
                } else {
                    console.error("Получены некорректные данные", data);
                }
            })
            .catch((error) => {
                console.error("Не удалось загрузить курсы:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleDelete = () => {
        if (!selectedCourseId) return;

        fetchWithAuth(`/api/admin/courses/${selectedCourseId}`, { method: "DELETE" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to delete course");
                }
                setCourses(courses.filter((course) => course.id !== selectedCourseId));
            })
            .catch((error) => console.error("Не удалось удалить курс:", error))
            .finally(() => {
                setOpenDialog(false);
                setSelectedCourseId(null);
            });
    };

    const handleOpenDialog = (courseId) => {
        setSelectedCourseId(courseId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedCourseId(null);
    };

    const handleEdit = (id) => {
        navigate(`/admin/course/${id}`);
    };

    const handleManageBookings = (id) => {
        navigate(`/admin/bookings/${id}`);
    };

    const handleDuplicate = (course) => {
        const duplicateData = {
            ...course,
            id: undefined, // Удаляем ID, чтобы сервер создал новый курс
            title: `${course.title} (Copy)`, // Добавляем суффикс для дубликата
        };

        fetchWithAuth("/api/admin/courses/add", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(duplicateData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to duplicate course");
                }
                return response.json();
            })
            .then((newCourse) => {
                setCourses((prevCourses) => [...prevCourses, newCourse]);
            })
            .catch((error) => console.error("Не удалось дублировать курс:", error));
    };

    const handleLanguageSwitch = (lang) => {
        setCurrentLanguage(lang);
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                <Typography variant="h4">Courses</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/admin/course/new")}
                >
                    Add New Course
                </Button>
            </Box>

            {/* Переключатель языка */}
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                {["en", "ru"].map((lang) => (
                    <IconButton
                        key={lang}
                        onClick={() => handleLanguageSwitch(lang)}
                        color={currentLanguage === lang ? "primary" : "default"}
                    >
                        <LanguageIcon />
                        <Typography variant="body1">{lang.toUpperCase()}</Typography>
                    </IconButton>
                ))}
            </Box>

            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={4}>
                    {Array.isArray(courses) && courses.length > 0 ? (
                        courses.map((course) => {
                            // Найти локализованные данные для текущего языка
                            const localization = course.localizations?.find(
                                (localization) => localization.language === currentLanguage
                            );
                            const courseTitle = localization ? localization.title : course.title;

                            return (
                                <Grid item xs={12} sm={6} md={4} key={course.id}>
                                    <Card sx={{ minHeight: 200, boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {courseTitle} {course.date}
                                            </Typography>

                                            <Box sx={{ marginTop: 2 }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => handleEdit(course.id)}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth
                                                    sx={{ marginTop: 1 }}
                                                    onClick={() => handleManageBookings(course.id)}
                                                >
                                                    Manage Bookings
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    fullWidth
                                                    sx={{ marginTop: 1 }}
                                                    onClick={() => handleOpenDialog(course.id)}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    fullWidth
                                                    sx={{ marginTop: 1 }}
                                                    onClick={() => handleDuplicate(course)}
                                                >
                                                    Duplicate
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                    ) : (
                        <Typography variant="h6" color="text.secondary">
                            No courses available
                        </Typography>
                    )}
                </Grid>
            )}

            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this course? This action cannot be undone."
            />
        </Container>
    );
}

export default AdminCourseList;