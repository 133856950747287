import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, CardHeader } from '@mui/material';
import axios from 'axios';
import ReviewForm from './ReviewForm'; // Importing the form for adding reviews
import { useTranslation } from 'react-i18next'; // Для перевода

function ReviewsPage() {
    const { t } = useTranslation(); // Получаем функцию перевода
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get('/api/reviews');
                setReviews(response.data);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchReviews();
    }, []);

    const handleNewReview = (newReview) => {
        setReviews((prevReviews) => [newReview, ...prevReviews]); // Добавляем новый отзыв в начало
    };

    const renderRating = (rating) => {
        return (
            <div style={{ display: 'inline-flex', gap: '2px' }}>
                {[...Array(5)].map((_, i) => (
                    <span key={i} style={{ fontSize: '20px', color: i < rating ? '#ffc107' : '#e0e0e0' }}>
                        ★
                    </span>
                ))}
            </div>
        );
    };

    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                {t('reviews')} {/* Переводим заголовок */}
            </Typography>

            <ReviewForm onReviewSubmit={handleNewReview} /> {/* Передаем функцию для добавления отзыва */}

            {reviews.length > 0 ? (
                reviews.map((review, index) => (
                    <Card key={index} style={{ marginBottom: '20px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                        <CardHeader
                            title={<Typography variant="h6" style={{ fontWeight: 'bold' }}>{review.name}</Typography>}
                            subheader={
                                <div>
                                    <Typography variant="body2" style={{ display: 'inline', marginRight: '8px' }}>
                                        {t('rating')}:
                                    </Typography>


                                    {renderRating(review.rating)}
                                </div>
                            }
                        />
                        <CardContent>
                            <Typography variant="body1" style={{ lineHeight: '1.6' }} dangerouslySetInnerHTML={{ __html: review.comment.replace(/\n/g, '<br />') }} />
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography variant="body1" color="textSecondary">
                    {t('noReviews')} {/* Переводим текст, если нет отзывов */}
                </Typography>
            )}
        </Container>
    );
}

export default ReviewsPage;
