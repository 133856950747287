import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EnrollmentForm from './EnrollmentForm';
import SeatsAvailability from './components/SeatsAvailability';
import './CourseDetail.css'; // Подключение CSS

function CourseDetail() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const [courseDetails, setCourseDetails] = useState(null);
    const [confirmation, setConfirmation] = useState(false);

    useEffect(() => {
        fetch(`/api/courses/${id}`)
            .then((response) => response.json())
            .then((data) => setCourseDetails(data))
            .catch((error) => console.error('Error fetching course details:', error));
    }, [id]);

    if (!courseDetails) {
        return <Typography variant="h6">{t('loading')}</Typography>;
    }

    const currentLanguage = i18n.language;

    // Находим локализованные данные для курса
    const localizedCourse = courseDetails.localizations?.find(
        (localization) => localization.language === currentLanguage
    ) || {};

    // Используем локализованные значения или fallback на английский (или другой язык по умолчанию)
    const title = localizedCourse.title || courseDetails.title;
    const date = localizedCourse.date || courseDetails.date || t('dateToBeAnnounced');
    const price = localizedCourse.price || courseDetails.price || t('priceToBeAnnounced');
    const location = localizedCourse.location || courseDetails.location || t('onlineOnSite');
    const duration = localizedCourse.duration || courseDetails.duration || t('flexible');
    const language = (() => {
        const courseLanguage = courseDetails.language || "flexible";

        if (courseLanguage === "en") {
            return currentLanguage === "ru" ? "Англ" : "Eng";
        }

        if (courseLanguage === "ru") {
            return currentLanguage === "ru" ? "Рус" : "Rus";
        }

        return courseLanguage; // Фолбэк на исходное значение
    })();


    // Локализованные данные для priceInclusions, priceExclusions, courseContent
    const priceInclusions = localizedCourse.priceInclusions || courseDetails.priceInclusions;
    const priceExclusions = localizedCourse.priceExclusions || courseDetails.priceExclusions;
    const courseContent = localizedCourse.courseContent || courseDetails.courseContent;

    const handleEnrollmentConfirm = (updatedCourse) => {
        setConfirmation(true);
        setCourseDetails(updatedCourse);
        localStorage.setItem('courseDetails', JSON.stringify(updatedCourse));
    };

    const areSeatsAvailable =
        courseDetails.availableSeats && courseDetails.availableSeats > 0;

    return (
        <Container maxWidth="md" className="course-detail">
            <Typography variant="h4" gutterBottom>
                {title}
            </Typography>

            <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}
                alignItems="center"
                marginBottom="20px"
            >
                <Box flex="1" sx={{ order: { xs: 2, md: 1 } }}>
                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('courseDate')}:</strong> {date}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('location')}:</strong>{' '}
                        {location ? (
                            <a
                                href={courseDetails.locationLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#1976d2', textDecoration: 'none' }}
                            >
                                {location}
                            </a>
                        ) : (
                            t('onlineOnSite')
                        )}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('duration')}:</strong> {duration}
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('language')}:</strong> {language}
                    </Typography>

                    <Box marginTop="20px" marginBottom="20px">
                        <Typography variant="h5" gutterBottom>
                            {t('coursePrice')}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{price}</strong>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {priceInclusions
                                ? t('priceIncludes')
                                : t('priceDetailsComingSoon')}
                        </Typography>
                        <ul className="course-list">
                            {priceInclusions &&
                                priceInclusions.map((item, index) => (
                                    <li key={index}>
                                        <Typography variant="body2" color="textSecondary">
                                            {item}
                                        </Typography>
                                    </li>
                                ))}
                        </ul>
                        {priceExclusions && (
                            <Box marginTop="20px">
                                <Typography variant="body2" color="textSecondary">
                                    {t('priceExcludes')}
                                </Typography>
                                <ul className="course-list">
                                    {priceExclusions.map((item, index) => (
                                        <li key={index}>
                                            <Typography variant="body2" color="textSecondary">
                                                {item}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        )}
                    </Box>

                    <Box marginTop="20px">
                        <Typography variant="h5" gutterBottom>
                            {t('courseContent')}
                        </Typography>
                        {courseContent ? (
                            <ul className="course-list">
                                {courseContent.map((topic, index) => (
                                    <li key={index}>
                                        <Typography variant="body2" color="textSecondary">
                                            {topic}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Typography variant="body1">{t('contentComingSoon')}</Typography>
                        )}
                    </Box>
                </Box>

                <Box flex="1" textAlign="center" sx={{ order: { xs: 1, md: 2 } }}>
                    <img
                        src={courseDetails.imageSrc}
                        alt={title}
                        className="course-detail-image"
                    />
                    <SeatsAvailability
                        totalSeats={courseDetails.seats}
                        occupiedSeats={courseDetails.occupiedSeats}
                    />
                </Box>
            </Box>

            {confirmation ? (
                <Box textAlign="center" marginTop="40px">
                    <Typography variant="h6">{t('thankYouForEnrolment')}</Typography>
                    <Typography variant="body1">{t('successfulEnrollment')}</Typography>
                </Box>
            ) : areSeatsAvailable ? (
                <Box className="enrollment-form-container">
                    <EnrollmentForm courseDetails={courseDetails} onConfirm={handleEnrollmentConfirm} />
                </Box>
            ) : (
                <Box textAlign="center" marginTop="40px">
                    <Typography variant="h6" color="error">
                        {t('noSeatsAvailable')}
                    </Typography>
                    <Typography variant="body1">{t('contactForMoreDetails')}</Typography>
                </Box>
            )}
        </Container>
    );
}

export default CourseDetail;
